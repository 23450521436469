import { VENDOR_ADDRESSES } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = VENDOR_ADDRESSES;

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    update(data) {
        return api.put(VENDOR_ADDRESSES, data);
    }
}