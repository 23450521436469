import { VENDOR_ATTACHMENTS } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = VENDOR_ATTACHMENTS;

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    store(data) {
        if (data === null) return;

        let formData = new FormData();

        for (let property in data) {
            formData.append(property, data[property]);
        }

        return api.post(VENDOR_ATTACHMENTS, formData);
    }
}