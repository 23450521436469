<template>
  <settings-section>
    <template v-slot:title> Documente </template>
    <template v-slot:subtitle> Contractul si alte acte </template>
    <section class="sm:px-4">
      <v-card class="mb-2">
        <v-card-body>
          <ul
            v-if="totalAttachments"
            role="list"
            class="border border-gray-200 rounded-md divide-y divide-gray-200"
          >
            <li
              v-for="row in rows"
              :key="row.id"
              class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div class="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-2 flex-1 w-0 truncate">
                  {{ row.name }}
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <a
                  :href="row.path"
                  class="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Download
                </a>
              </div>
            </li>
          </ul>
          <v-card-body-title v-else>Niciun document gasit</v-card-body-title>
        </v-card-body>
      </v-card>
    </section>
    <tax-information-section-attachment-form @created:attachment="index" />
  </settings-section>
</template>

<script>
import SettingsSection from "./SettingsSection.vue";
import TaxInformationSectionAttachmentForm from "./TaxInformationSectionAttachmentForm.vue";
import vendorAttachmentsApi from "../api/resources/vendorAttachmentsApi";
import { PaperClipIcon } from "@heroicons/vue/solid";
import VCard from "./VCard.vue";
import VCardBody from "./VCardBody.vue";
import VCardBodyTitle from "./VCardBodyTitle.vue";

export default {
  components: {
    SettingsSection,
    TaxInformationSectionAttachmentForm,
    PaperClipIcon,
    VCard,
    VCardBody,
    VCardBodyTitle,
  },
  data() {
    return {
      rows: [],
      loading: false,
    };
  },

  computed: {
    totalAttachments() {
      return this.rows.length;
    },
  },

  methods: {
    index(page = null) {
      this.loading = true;

      vendorAttachmentsApi
        .index(page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
