<template>
  <form
    action="#"
    method="POST"
    class="sm:px-4"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
  >
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 py-5 bg-white sm:p-6">
        <v-label for="attachment"> Incarca document </v-label>
        <v-input type="file" name="attachment" @change="onFileChange"></v-input>
        <v-error :error="form.errors.get('attachment')"></v-error>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <v-button-primary type="submit" :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button-primary>
      </div>
    </div>
  </form>
</template>

<script>
import Form from "@/core/Form";
import vendorAttachmentsApi from "@/api/resources/vendorAttachmentsApi";
import VError from "@/components/VError.vue";
import VInput from "@/components/VInput.vue";
import VLabel from "@/components/VLabel.vue";
import VButtonPrimary from "@/components/VButtonPrimary.vue";
import VButtonIcon from "@/components/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    VLabel,
    VError,
    VInput,
    VButtonPrimary,
    VButtonIcon,
    Loader,
  },
  emits: ["created:attachment"],
  data() {
    return {
      form: new Form({
        attachment: "",
      }),
      vendor: {},
    };
  },
  methods: {
    onFileChange(e) {
      let files = Array.from(e.target.files || e.dataTransfer.files);
      this.form.attachment = files[0];
    },

    onSubmit() {
      this.form
        .submitService(vendorAttachmentsApi, "store")
        .then(() => {
          this.$emit("created:attachment");
        });
    },
  },
  created() {
    // vendorAttachmentsApi.index().then((response) => {
    //   this.form.setData(response.data[0].address);
    // });
  },
};
</script>

<style></style>
