<template>
  <form
    action="#"
    method="POST"
    class="sm:px-4"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
  >
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="mb-6">
          <v-label for="name"> Nume </v-label>
          <v-input type="text" name="name" v-model="form.name"></v-input>
          <v-error :error="form.errors.get('name')"></v-error>
        </div>

        <div class="flex flex-col md:flex-row md:space-x-6">
          <div class="mb-6 flex-1">
            <v-label for="fiscal_code"> Cod fiscal </v-label>
            <v-input
              type="text"
              name="fiscal_code"
              v-model="form.fiscal_code"
            ></v-input>
            <v-error :error="form.errors.get('fiscal_code')"></v-error>
          </div>
          <div class="mb-6 flex-1">
            <v-label for="registration_number"> Numar inregistrare </v-label>
            <v-input
              type="text"
              name="registration_number"
              v-model="form.registration_number"
            ></v-input>
            <v-error :error="form.errors.get('registration_number')"></v-error>
          </div>
        </div>
        <div class="flex flex-col md:flex-row md:space-x-6">
          <div class="mb-6 flex-1">
            <v-label for="series"> Serie </v-label>
            <v-input type="text" name="series" v-model="form.series"></v-input>
            <v-error :error="form.errors.get('series')"></v-error>
          </div>
          <div class="mb-6 flex-1">
            <v-label for="sequence"> Numar </v-label>
            <v-input
              type="text"
              name="sequence"
              v-model="form.sequence"
            ></v-input>
            <v-error :error="form.errors.get('sequence')"></v-error>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <v-button-primary type="submit" :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button-primary>
      </div>
    </div>
  </form>
</template>

<script>
import Form from "@/core/Form";
import vendorsApi from "@/api/resources//vendorsApi";
import VError from "@/components/VError.vue";
import VInput from "@/components/VInput.vue";
import VLabel from "@/components/VLabel.vue";
import VButtonPrimary from "@/components/VButtonPrimary.vue";
import VButtonIcon from "@/components/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: { VLabel, VError, VInput, VButtonPrimary, VButtonIcon, Loader },
  data() {
    return {
      form: new Form({
        name: "",
        fiscal_code: "",
        registration_number: "",
        series: "",
        sequence: "",
      }),
      vendor: {},
    };
  },
  methods: {
    onSubmit() {
      this.form.submitService(vendorsApi, "update").then((response) => {
        this.form.setData(response.data);
      });
    },
  },
  created() {
    vendorsApi.show().then((response) => {
      this.form.setData(response.data);
    });
  },
};
</script>

<style></style>
