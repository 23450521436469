<template>
  <settings-section>
    <template v-slot:title> Adresa sediu </template>
    <template v-slot:subtitle> Sunt necesare pentru a emite facturi </template>
    <tax-information-section-address-form />
  </settings-section>
</template>

<script>
import SettingsSection from "./SettingsSection.vue";
import TaxInformationSectionAddressForm from "./TaxInformationSectionAddressForm.vue";
export default {
  components: { SettingsSection, TaxInformationSectionAddressForm },
};
</script>

<style>
</style>