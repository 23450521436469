<template>
  <form
    action="#"
    method="POST"
    class="sm:px-4"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
  >
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="flex flex-col md:flex-row md:space-x-6">
          <div class="mb-6 flex-1">
            <v-label for="street"> Strada </v-label>
            <v-input type="text" name="street" v-model="form.street"></v-input>
            <v-error :error="form.errors.get('street')"></v-error>
          </div>
          <div class="mb-6">
            <v-label for="postal_code"> Cod postal </v-label>
            <v-input
              type="text"
              name="postal_code"
              v-model="form.postal_code"
            ></v-input>
            <v-error :error="form.errors.get('postal_code')"></v-error>
          </div>
        </div>

        <div class="flex flex-col md:flex-row md:space-x-6">
          <div class="mb-6 md:w-1/3">
            <v-label for="locality"> Localitate </v-label>
            <v-input
              type="text"
              name="locality"
              v-model="form.locality"
            ></v-input>
            <v-error :error="form.errors.get('locality')"></v-error>
          </div>
          <div class="mb-6 md:w-1/3">
            <v-label for="region"> Judet </v-label>
            <v-input type="text" name="region" v-model="form.region"></v-input>
            <v-error :error="form.errors.get('region')"></v-error>
          </div>
          <div class="mb-6 md:w-1/3">
            <v-label for="country_id"> Tara </v-label>
            <select-country
              select-name="country_id"
              class="mt-1 w-full"
              v-model="form.country_id"
            />
            <v-error :error="form.errors.get('country_id')"></v-error>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <v-button-primary type="submit" :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button-primary>
      </div>
    </div>
  </form>
</template>

<script>
import Form from "@/core/Form";
import vendorAddressesApi from "@/api/resources/vendorAddressesApi";
import VError from "@/components/VError.vue";
import VInput from "@/components/VInput.vue";
import VLabel from "@/components/VLabel.vue";
import SelectCountry from "@/components/shared/SelectCountry.vue";
import VButtonPrimary from "@/components/VButtonPrimary.vue";
import VButtonIcon from "@/components/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    VLabel,
    VError,
    VInput,
    SelectCountry,
    VButtonPrimary,
    VButtonIcon,
    Loader,
  },
  data() {
    return {
      form: new Form({
        street: "",
        postal_code: "",
        locality: "",
        region: "",
        country_id: "",
      }),
      vendor: {},
    };
  },
  methods: {
    onSubmit() {
      this.form.submitService(vendorAddressesApi, "update").then((response) => {
        this.form.setData(response.data);
      });
    },
  },
  created() {
    vendorAddressesApi.index().then((response) => {
      this.form.setData(response.data[0].address);
    });
  },
};
</script>

<style></style>
