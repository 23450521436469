<template>
  <settings-section>
    <template v-slot:title> Date firma </template>
    <template v-slot:subtitle>
      Status
      <span class="uppercase" :class="statusClasses"> {{ vendorStatus }} </span>
    </template>
    <tax-information-section-vendor-form />
  </settings-section>
</template>

<script>
import SettingsSection from "./SettingsSection.vue";
import TaxInformationSectionVendorForm from "./TaxInformationSectionVendorForm.vue";
import vendorsApi from "@/api/resources//vendorsApi";
export default {
  components: { SettingsSection, TaxInformationSectionVendorForm },
  data() {
    return {
      vendor: {},
    };
  },
  computed: {
    vendorStatus() {
      return this.vendor?.status?.label;
    },
    statusClasses() {
      return this.vendor?.status?.name === "approved"
        ? "text-green-700"
        : "text-yellow-700";
    },
  },
  created() {
    vendorsApi.show().then((response) => {
      this.vendor = response.data;
    });
  },
};
</script>

<style></style>
