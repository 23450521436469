<template>
  <app-layout>
    <page-header class="mb-4"> Informatii fiscale </page-header>

    <tax-information-section-vendor />

    <settings-section-separator />

    <tax-information-section-address />

    <settings-section-separator />

    <tax-information-section-attachment/>

    <settings-section-separator />
  </app-layout>
</template>

<script>
import PageHeader from "../components/PageHeader.vue";
import SettingsSectionSeparator from "../components/SettingsSectionSeparator.vue";
import TaxInformationSectionAddress from "../components/TaxInformationSectionAddress.vue";
import TaxInformationSectionAttachment from "../components/TaxInformationSectionAttachment.vue";
import TaxInformationSectionVendor from "../components/TaxInformationSectionVendor.vue";
import AppLayout from "../layout/AppLayout.vue";

export default {
  components: {
    AppLayout,
    SettingsSectionSeparator,
    TaxInformationSectionVendor,
    TaxInformationSectionAddress,
    TaxInformationSectionAttachment,
    PageHeader,
  },
};
</script>
