<template>
  <div class="md:grid md:grid-cols-3 md:gap-6 mb-10 sm:mb-0">
    <div class="md:col-span-1">
      <div class="px-4">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          <slot name="title"></slot>
        </h3>
        <p class="mt-1 text-sm text-gray-600">
          <slot name="subtitle"></slot>
        </p>
      </div>
    </div>
    <div class="mt-5 md:mt-0 md:col-span-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
